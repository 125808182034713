.divider {
  margin-top: 10px;
  margin-bottom: 10px;
  animation: gradient 2s linear infinite;
  animation-direction: alternate;
  background: linear-gradient(90deg, #1c4da1, #3fb1e5 50%, #ea3f33 75%);
  background-size: 120% 130%;
  width: 80%;
  text-align: center;
  display: block;
}
